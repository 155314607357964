export const Alert = () => import('../../components/core/Alert.vue' /* webpackChunkName: "components/alert" */).then(c => wrapFunctional(c.default || c))
export const Button = () => import('../../components/core/Button.vue' /* webpackChunkName: "components/button" */).then(c => wrapFunctional(c.default || c))
export const Card = () => import('../../components/core/Card.vue' /* webpackChunkName: "components/card" */).then(c => wrapFunctional(c.default || c))
export const Checkbox = () => import('../../components/core/Checkbox.vue' /* webpackChunkName: "components/checkbox" */).then(c => wrapFunctional(c.default || c))
export const Dropdown = () => import('../../components/core/Dropdown.vue' /* webpackChunkName: "components/dropdown" */).then(c => wrapFunctional(c.default || c))
export const Input = () => import('../../components/core/Input.vue' /* webpackChunkName: "components/input" */).then(c => wrapFunctional(c.default || c))
export const MenuItem = () => import('../../components/core/MenuItem.vue' /* webpackChunkName: "components/menu-item" */).then(c => wrapFunctional(c.default || c))
export const AppFooter = () => import('../../components/AppFooter.vue' /* webpackChunkName: "components/app-footer" */).then(c => wrapFunctional(c.default || c))
export const AppHeader = () => import('../../components/AppHeader.vue' /* webpackChunkName: "components/app-header" */).then(c => wrapFunctional(c.default || c))
export const Breadcrumb = () => import('../../components/Breadcrumb.vue' /* webpackChunkName: "components/breadcrumb" */).then(c => wrapFunctional(c.default || c))
export const FaqList = () => import('../../components/FaqList.vue' /* webpackChunkName: "components/faq-list" */).then(c => wrapFunctional(c.default || c))
export const PageLoader = () => import('../../components/PageLoader.vue' /* webpackChunkName: "components/page-loader" */).then(c => wrapFunctional(c.default || c))
export const PriceChart = () => import('../../components/PriceChart.vue' /* webpackChunkName: "components/price-chart" */).then(c => wrapFunctional(c.default || c))
export const SocialLogin = () => import('../../components/SocialLogin.vue' /* webpackChunkName: "components/social-login" */).then(c => wrapFunctional(c.default || c))
export const TopCurrencyList = () => import('../../components/TopCurrencyList.vue' /* webpackChunkName: "components/top-currency-list" */).then(c => wrapFunctional(c.default || c))
export const TopCurrencyListMobile = () => import('../../components/TopCurrencyListMobile.vue' /* webpackChunkName: "components/top-currency-list-mobile" */).then(c => wrapFunctional(c.default || c))
export const Header = () => import('../../components/header/Index.vue' /* webpackChunkName: "components/header" */).then(c => wrapFunctional(c.default || c))
export const HeaderMenu = () => import('../../components/header/Menu.vue' /* webpackChunkName: "components/header-menu" */).then(c => wrapFunctional(c.default || c))
export const HeaderMenuToggler = () => import('../../components/header/MenuToggler.vue' /* webpackChunkName: "components/header-menu-toggler" */).then(c => wrapFunctional(c.default || c))
export const HomeProductCard = () => import('../../components/home/ProductCard.vue' /* webpackChunkName: "components/home-product-card" */).then(c => wrapFunctional(c.default || c))
export const HomeProductList = () => import('../../components/home/ProductList.vue' /* webpackChunkName: "components/home-product-list" */).then(c => wrapFunctional(c.default || c))
export const HomeTopSeller = () => import('../../components/home/TopSeller.vue' /* webpackChunkName: "components/home-top-seller" */).then(c => wrapFunctional(c.default || c))
export const ProductContent = () => import('../../components/product/ProductContent.vue' /* webpackChunkName: "components/product-content" */).then(c => wrapFunctional(c.default || c))
export const ProductSellerCard = () => import('../../components/product/SellerCard.vue' /* webpackChunkName: "components/product-seller-card" */).then(c => wrapFunctional(c.default || c))
export const HeaderAuthGuest = () => import('../../components/header/auth/Guest.vue' /* webpackChunkName: "components/header-auth-guest" */).then(c => wrapFunctional(c.default || c))
export const HeaderAuth = () => import('../../components/header/auth/Index.vue' /* webpackChunkName: "components/header-auth" */).then(c => wrapFunctional(c.default || c))
export const HeaderAuthUser = () => import('../../components/header/auth/User.vue' /* webpackChunkName: "components/header-auth-user" */).then(c => wrapFunctional(c.default || c))
export const HomeSidebar = () => import('../../components/home/sidebar/Index.vue' /* webpackChunkName: "components/home-sidebar" */).then(c => wrapFunctional(c.default || c))
export const PagesContractsCookiePolicy = () => import('../../components/pages/contracts/CookiePolicy.vue' /* webpackChunkName: "components/pages-contracts-cookie-policy" */).then(c => wrapFunctional(c.default || c))
export const PagesContractsKvkk = () => import('../../components/pages/contracts/Kvkk.vue' /* webpackChunkName: "components/pages-contracts-kvkk" */).then(c => wrapFunctional(c.default || c))
export const PagesContractsMembershipAgreement = () => import('../../components/pages/contracts/MembershipAgreement.vue' /* webpackChunkName: "components/pages-contracts-membership-agreement" */).then(c => wrapFunctional(c.default || c))
export const PagesContractsPrivacyPolicy = () => import('../../components/pages/contracts/PrivacyPolicy.vue' /* webpackChunkName: "components/pages-contracts-privacy-policy" */).then(c => wrapFunctional(c.default || c))
export const PagesContractsSidebar = () => import('../../components/pages/contracts/Sidebar.vue' /* webpackChunkName: "components/pages-contracts-sidebar" */).then(c => wrapFunctional(c.default || c))
export const PagesCategoryProductCard = () => import('../../components/pages/category/CategoryProductCard.vue' /* webpackChunkName: "components/pages-category-product-card" */).then(c => wrapFunctional(c.default || c))
export const PagesRegisterVerificationCodeInput = () => import('../../components/pages/register/VerificationCodeInput.vue' /* webpackChunkName: "components/pages-register-verification-code-input" */).then(c => wrapFunctional(c.default || c))
export const PagesSettingsAccount = () => import('../../components/pages/settings/Account.vue' /* webpackChunkName: "components/pages-settings-account" */).then(c => wrapFunctional(c.default || c))
export const PagesSettingsLike = () => import('../../components/pages/settings/Like.vue' /* webpackChunkName: "components/pages-settings-like" */).then(c => wrapFunctional(c.default || c))
export const PagesSettingsPassword = () => import('../../components/pages/settings/Password.vue' /* webpackChunkName: "components/pages-settings-password" */).then(c => wrapFunctional(c.default || c))
export const PagesSettingsProfile = () => import('../../components/pages/settings/Profile.vue' /* webpackChunkName: "components/pages-settings-profile" */).then(c => wrapFunctional(c.default || c))
export const PagesSettingsSidebar = () => import('../../components/pages/settings/Sidebar.vue' /* webpackChunkName: "components/pages-settings-sidebar" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
