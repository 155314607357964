import { render, staticRenderFns } from "./default.vue?vue&type=template&id=7dd475a0&"
import script from "./default.vue?vue&type=script&lang=js&"
export * from "./default.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AppHeader: require('/app/components/AppHeader.vue').default,TopCurrencyList: require('/app/components/TopCurrencyList.vue').default,TopCurrencyListMobile: require('/app/components/TopCurrencyListMobile.vue').default,AppFooter: require('/app/components/AppFooter.vue').default})
