import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _35349797 = () => interopDefault(import('../pages/altin/index.vue' /* webpackChunkName: "pages/altin/index" */))
const _7fc2bd76 = () => interopDefault(import('../pages/doviz/index.vue' /* webpackChunkName: "pages/doviz/index" */))
const _158d901b = () => interopDefault(import('../pages/giris-yap.vue' /* webpackChunkName: "pages/giris-yap" */))
const _1eb20bd5 = () => interopDefault(import('../pages/hakkimizda.vue' /* webpackChunkName: "pages/hakkimizda" */))
const _73ec22cd = () => interopDefault(import('../pages/iletisim/index.vue' /* webpackChunkName: "pages/iletisim/index" */))
const _5f8f00b4 = () => interopDefault(import('../pages/kayit-ol.vue' /* webpackChunkName: "pages/kayit-ol" */))
const _552356ea = () => interopDefault(import('../pages/sifre-sifirla.vue' /* webpackChunkName: "pages/sifre-sifirla" */))
const _5ab28ba8 = () => interopDefault(import('../pages/sifremi-unuttum.vue' /* webpackChunkName: "pages/sifremi-unuttum" */))
const _337df64f = () => interopDefault(import('../pages/altin/_slug.vue' /* webpackChunkName: "pages/altin/_slug" */))
const _073f1556 = () => interopDefault(import('../pages/ayarlar/_slug.vue' /* webpackChunkName: "pages/ayarlar/_slug" */))
const _83300006 = () => interopDefault(import('../pages/doviz/_slug.vue' /* webpackChunkName: "pages/doviz/_slug" */))
const _ab9e41e8 = () => interopDefault(import('../pages/profil/_username.vue' /* webpackChunkName: "pages/profil/_username" */))
const _97a8aa0e = () => interopDefault(import('../pages/sozlesme/_slug.vue' /* webpackChunkName: "pages/sozlesme/_slug" */))
const _2dfb1658 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _1e6ca7a6 = () => interopDefault(import('../pages/_category/index.vue' /* webpackChunkName: "pages/_category/index" */))
const _10f720e8 = () => interopDefault(import('../pages/_category/_product.vue' /* webpackChunkName: "pages/_category/_product" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/altin",
    component: _35349797,
    name: "altin"
  }, {
    path: "/doviz",
    component: _7fc2bd76,
    name: "doviz"
  }, {
    path: "/giris-yap",
    component: _158d901b,
    name: "giris-yap"
  }, {
    path: "/hakkimizda",
    component: _1eb20bd5,
    name: "hakkimizda"
  }, {
    path: "/iletisim",
    component: _73ec22cd,
    name: "iletisim"
  }, {
    path: "/kayit-ol",
    component: _5f8f00b4,
    name: "kayit-ol"
  }, {
    path: "/sifre-sifirla",
    component: _552356ea,
    name: "sifre-sifirla"
  }, {
    path: "/sifremi-unuttum",
    component: _5ab28ba8,
    name: "sifremi-unuttum"
  }, {
    path: "/altin/:slug",
    component: _337df64f,
    name: "altin-slug"
  }, {
    path: "/ayarlar/:slug?",
    component: _073f1556,
    name: "ayarlar-slug"
  }, {
    path: "/doviz/:slug",
    component: _83300006,
    name: "doviz-slug"
  }, {
    path: "/profil/:username?",
    component: _ab9e41e8,
    name: "profil-username"
  }, {
    path: "/sozlesme/:slug?",
    component: _97a8aa0e,
    name: "sozlesme-slug"
  }, {
    path: "/",
    component: _2dfb1658,
    name: "index"
  }, {
    path: "/:category",
    component: _1e6ca7a6,
    name: "category"
  }, {
    path: "/:category/:product",
    component: _10f720e8,
    name: "category-product"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
